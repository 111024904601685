import React from 'react'
import { graphql, Link } from "gatsby"
import { Col, Container, Row } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotlightArticle from '../components/SpotlightArticle'

export default function LearningCalender(props) {
    return (
        <Layout pageInfo={{ pageName: "Learning Calender" }}>
            <SEO title="Learning Calender" />
            <SpotlightArticle tags={`Learning Calender`} title={``} height={30} bgColor={`#36d5d4`} />
            <div className="calender">
                <Container>
                    <Row>
                        <Col>
                            {props.data.allIntranetPages.edges.map(calender => {
                                return(
                                    <>
                                    <small className="breadcrumb">
                                        <Link to="/">Home</Link> <div className="separator">/</div> <Link to={`/other-essentials`}>Other Essentials</Link> <div className="separator">/</div> {calender.node.title}
                                    </small>
                                    <div className="calender-img" key={calender.node.id}>
                                        <img src={calender.node.image} alt="" />
                                    </div>
                                    </>
                                )
                            })}
                            {/* <div className="calender-img"><img src={holidaycalender} alt="" /></div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}
export const query = graphql`
    query LearningCalender {
        allIntranetPages(filter: {slug: {eq: "learning-calendar"}}) {
            edges {
                node {
                    title
                    excerpt
                    id
                    slug
                    link
                    image
                }
            }
        }
    }
`